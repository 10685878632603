import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private darkModeSubject = new BehaviorSubject<boolean>(false);
  public darkMode$ = this.darkModeSubject.asObservable();

  constructor() {
    const isDarkMode = localStorage.getItem('darkMode') === 'true';
    this.darkModeSubject.next(isDarkMode);
    this.applyTheme(isDarkMode);
  }

  toggleDarkMode() {
    const darkMode = !this.darkModeSubject.value;
    this.darkModeSubject.next(darkMode);
    localStorage.setItem('darkMode', darkMode.toString());
    this.applyTheme(darkMode);
  }

  private applyTheme(darkMode: boolean) {
    const body = document.body;
    if (darkMode) {
      body.classList.add('dark');
    } else {
      body.classList.remove('dark');
    }
  }

}
